@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.noScrollbar {
  -ms-overflow-style: none !important; /* Internet Explorer 10+ and Edge */
  scrollbar-width: none !important; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.noScrollbar::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}
